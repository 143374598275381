import * as React from "react"
import {useState,useEffect,useMemo} from "react";
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { useSelector, useDispatch, } from 'react-redux'
import {USD, USD_P2} from '../helpers/NumberHelper'
import CategoryImage from "../components/products/CategoryImage";
import {removeProduct, updateTax} from "../state/cart";
import {updateCheckoutField} from "../state/checkout";
import { Link } from "gatsby"
import debounce from 'lodash.debounce';

const LoadingIndicator = () => {
   return (
       <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
        </svg>
   )
}

const ShippingField = ({onChange, type, name, placeholder, checkout, className}) => {
    return (
        <><input type={type||"text"} name={name} placeholder={placeholder} className={className||"w-full p-2"}
                    value={checkout[name]} onChange={onChange}/></>
    )
}

const ShoppingBag = () => {
    const cartProducts = useSelector((state) => state.cart.products)
    const dispatch = useDispatch()
    return (
        <div>
            <div className={"grid grid-cols-7 gap-4 my-4 text-sm text-gray-600"}>
                <div className={"col-span-2"}>Product</div>
                <div>Color</div>
                <div>Size</div>
                <div>Unit Price</div>
                <div>Quantity</div>
                <div>Total</div>
            </div>
            <div>
                {
                    cartProducts.map((p) => {
                        return (
                            <div key={p.sku} className={"grid grid-cols-7 gap-4"}>
                                <div className={"h-64"}>
                                    <CategoryImage sku={p.sku}
                                                   alt={p.name}
                                                   title={p.title}
                                                   url={'/' + p.url}
                                                   normal={p.image}
                                                   thumb={p.image}
                                    />
                                </div>
                                <div>
                                    {p.brand && p.model ? <>
                                            <p className={"text-bold"}>{p.brand}</p>
                                            <p className={"mb-8"}>{p.model} {p.size}</p>
                                        </> :
                                        <p className={"text-bold mb-8"}>{p.name}</p>}
                                </div>
                                <div>
                                    {p.color ? <p>{p.color}</p> : 'N/A'}
                                </div>
                                <div>
                                    {p.size ? <p>{p.size}</p> : 'N/A'}
                                </div>
                                <div>
                                    {USD(p.price)}
                                </div>
                                <div>

                                    {p.qty ? <p>{p.qty}</p> : ''}
                                    <button className={"py-2 text-cta"}
                                            onClick={() => dispatch(removeProduct({
                                                sku: p.sku,
                                                qty: p.qty
                                            }))}>
                                        Remove
                                    </button>
                                </div>
                                <div className={"flex-none font-bold w-16"}>
                                    {USD(p.price * p.qty)}
                                </div>
                            </div>
                        )
                    })
                }
                <hr/>
            </div>
        </div>
    )
}

const ShoppingBagPage = () => {
    const cartTotal = useSelector((state) => state.cart.total)
    const cartProducts = useSelector((state) => state.cart.products)
    const shipping = useSelector((state) => state.cart.shipping)
    const tax = useSelector((state) => state.cart.tax)
    const checkout = useSelector(state => state.checkout);
    const dispatch = useDispatch()
    const [showShipping, setShowShipping] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const confirmTax = useMemo(
    () => debounce(
        (shippingInfo) =>
            {
                const url = '/api/confirm-tax';
                setIsLoading(true);
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(shippingInfo),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                }).then(response => {
                    setIsLoading(false);
                    if (response.ok) {
                        response.json().then(taxInfo => {
                            dispatch(updateTax(taxInfo))
                            console.log(taxInfo);
                        });
                    } else {
                        console.log("HTTP-Error: " + response.status);
                    }
                })
    }, 1000), [dispatch]);
    // Cleanup
    useEffect(() => {return () => {confirmTax.cancel();}},[confirmTax])

    // const confirmTax = useCallback(debounce((shippingInfo) => , 1000), [setIsLoading, dispatch, updateTax]);


    useEffect(() => {
        if(checkout.shipping_address && checkout.shipping_city && checkout.shipping_zip && checkout.shipping_state && checkout.shipping_country) {
           const shippingInfo = {...checkout, cart: cartProducts.map(item => ({
                   sku: item.sku,
                   qty: item.qty,
                   price: item.price
               })),
               cartTotal,
               shipping
           }
            confirmTax(shippingInfo)
        }
    }, [checkout, cartProducts, cartTotal, shipping, confirmTax])


    const updateShipping = (e) => {
        console.log('Update checkout field', e.target.name, e.target.value)
        dispatch(updateCheckoutField({name: e.target.name, value: e.target.value}))
    }


    return (
        <Layout breadcrumbs={false}>
            <Seo title="Shopping Bag" noindex={true}/>
            <h1 className={"my-4"}>Shopping Bag</h1>
            <ShoppingBag  />
            <div className={"flex"}>
                <div className={"bg-box p-4 w-80 my-4"}>
                    <h2 className={"text-center mb-4"}> Order Summary</h2>

                    <div className={"bg-white my-4 p-4 grid grid-cols-2 gap-4"}>
                        <div>Items Subtotal</div>
                        <div className={"text-right"}>{USD_P2(cartTotal)}</div>
                        <div>Shipping*</div>
                        <div className={"text-right"}>{isLoading? <LoadingIndicator /> :""} {USD_P2(shipping)}</div>
                        <div>Tax*</div>
                        <div className={"text-right"}>{isLoading? <LoadingIndicator /> :""} {USD_P2(tax)}</div>
                    </div>
                    <div className={"my-4 p-4 grid grid-cols-2 gap-4"}>
                        <div>Order Total</div>
                        <div className={"text-right"}>{USD_P2(parseFloat(cartTotal) + parseFloat(shipping) + parseFloat(tax))}</div>
                    </div>

                    <div className={"my-4 p-4 grid grid-cols-1 gap-4"}>
                        <div><i>* <button className={"text-blue underline"} onClick={() => setShowShipping(!showShipping)}>provide
                            your shipping address</button> for accurate tax & shipping calculation</i></div>
                    </div>
                    <Link to={"/checkout/"}
                          className={"bg-cta my-4 block w-full text-center text-white p-4"}>Checkout</Link>

                </div>
                {showShipping?
                <div className={"m-4 p-4 bg-box w-80"}>
                    <h2 className={"w-full"}>Shipping Address</h2>
                    {/*<ShippingField name={"shipping_name"} onChange={updateShipping} checkout={checkout} placeholder={"First Name"} />*/}
                    {/*<ShippingField name={"shipping_last_name"} onChange={updateShipping} checkout={checkout} placeholder={"Last Name"} />*/}
                    <div className={"w-full my-4"}><ShippingField name={"shipping_address"} onChange={updateShipping} checkout={checkout} placeholder={"Address"} /></div>
                    <div className={"w-full my-4"}><ShippingField name={"shipping_address2"} onChange={updateShipping} checkout={checkout} placeholder={"Address 2"} /></div>
                    <div className={"w-full my-4 flex gap-2"}>
                        <ShippingField name={"shipping_city"} className={"w-1/3 p-2"}
                                       onChange={updateShipping} checkout={checkout} placeholder={"City"} />
                        <ShippingField name={"shipping_zip"} className={"w-1/3 p-2"} onChange={updateShipping} checkout={checkout} placeholder={"ZIP Code"} />
                        <ShippingField name={"shipping_state"} className={"w-1/3 p-2"} onChange={updateShipping} checkout={checkout} placeholder={"State"} />
                    </div>
                    <div><ShippingField name={"shipping_country"} onChange={updateShipping} checkout={checkout} placeholder={"Country"} /></div>
                </div>
                    :
                    ""}
            </div>
        </Layout>
    )
}

export default ShoppingBagPage
